@import '../../../styles/customMediaQueries.css';

.root {
  z-index: 9;
  background-color: #f0f0f0;
}

.root > div {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  padding: 36px 24px;
  width: 100vw;

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: 0.90fr 1.25fr;
    grid-template-rows: auto;
    flex-direction: row;
    padding: 60px;
    margin: 0 auto;
  }
}

.titleBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    height: 300px;
    width: 50%;
  }
}

.videoBox {
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 0;

  @media (--viewportMedium) {
    height: 300px;
    width: 50%;
  }
}

.video {
  width: 100%;

  @media (--viewportMedium) {
    min-height: 310px;
  }
}

.image {
  width: 100%;
  height: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (--viewportMedium) {
    height: 181px;
  }
}

.title {
  color: var(--colorBlueTextDark);
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  padding: 0;
  margin: 0;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.subTitle {
  color: var(--colorBlueButton);
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin: 10px 0 10px 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 10px 0 20px 0;
  }
}

.routerLink {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
}

.button {
  width: 100%;
  max-width: 158px;
  min-height: 36px;
  height: 36px;
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  line-height: 14px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 16px;
  }
}

.first,
.second {
  width: 100%;
}
