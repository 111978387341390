@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 30px auto;
  padding: 32px;
  @media (--viewportMedium) {
    padding: 0 24px;
  }
  @media (--viewportLarge) {
    padding: 32px;
  }
}
.featuresMain {
  composes: baseColumn;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-auto-flow: dense;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row-reverse;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
