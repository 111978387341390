@import '../../../../styles/customMediaQueries.css';

.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
}

.text > h2 {
  line-height: unset;

  @media (--viewportMedium) {
    line-height: 40px;
  }
}

.text > p {
  font-size: 13px;
  line-height: 1.5em;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}
