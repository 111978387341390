@import '../../../../styles/customMediaQueries.css';

.container {
  padding: 16px;
  background: var(--colorBlueLight);
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: calc(100vw - 70px);
  margin: 0 auto;

  @media (--viewportMedium) {
    max-width: 800px;
    padding: 18px;
    border-radius: 8px;
  }
}

.searchPredictions {
  position: absolute;
  width: 100%;
  top: var(--topbarHeightDesktop);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    max-width: 840px;
    margin-top: 0px;
  }
}

.searchInputIcon {
  transform: scaleX(-1);
  display: flex;
  width: 18px;
  min-height: 60px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--colorWhite);
  padding-right: 8px;

  @media (--viewportMedium) {
    width: 24px;
  }
}

.searchInput {
  composes: p from global;
  width: calc(100% - 120px);
  flex-grow: 1;
  /* Font */
  height: 60px !important;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-top: 0;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
    color: #002c4c;
  }

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
    margin-top: 0;
    margin-bottom: 0;
  }
}