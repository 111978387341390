@import '../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    padding-bottom: 0;
    margin-top: 80px;
  }
}

.videoContainer {
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  @media (--viewportMedium) {
    height: 680px;
  }
}

.sectionContent::after {
  content: url('./media/bottomMobilImg.webp');
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  bottom: 110px;

  @media (--viewportMedium) {
    bottom: -22px;
  }

  @media (--viewportLarge) {
    content: none;
  }
}

.videoBgLanding {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  @media (--viewportLarge) {
    width: 100%;
  }
}

.sectionContent {
  padding: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: calc(100vh - 122px);
  display: flex;

  @media (--viewportMedium) {
    height: 526px;
  }

  @media (--viewportLarge) {
    height: 680px;
  }
}

.opacityImg {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #002c4c 58%, #f2295b00 100%);
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.bgImgContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.title {
  font-size: 28px !important;
  font-weight: var(--fontWeightBold);
  text-align: center;
  line-height: 1;
  letter-spacing: normal;
  margin: 0 0 10px 0;
  padding: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin-top: 136px;
    font-size: 64px !important;
  }

  @media (--viewportLarge) {
    margin-top: 56px;
  }
}

.subTitle {
  color: var(--colorWhite);
  text-align: center !important;
  font-size: 14px !important;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 20px;
  width: calc(100vw - 70px);
  max-width: 700px;
  line-height: 1.3em;
  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 32px !important;
    margin-bottom: 20px;
    line-height: 42px;
  }
}
